// react

import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
import { ArrowDown, ArrowRoundedDown12x7Svg, Check9x7Svg } from "../../svg";
import { FormattedMessage } from "react-intl";

function FilterCheck(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [textColor, setTextColor] = useState("black");
    const { data, value, onChangeValue, filterValues } = props;

    const updateValue = (data, newValue, remove, code) => {
        onChangeValue({ filter: code, value: newValue, remove: remove });
    };

    const handleChange = (event, value, code) => {
        if (event.target.checked) {
            updateValue(value, [event.target.value], false, code);
        }
        if (!event.target.checked) {
            updateValue(value, event.target.value, true, code);
        }
    };

    let fillVal;
    if (filterValues[value] !== undefined) {
        fillVal = filterValues[value].split(",");
    }

    const itemsList = data.map((item) => {
        const itemClasses = classNames("filter-list__item", {
            "filter-list__item--disabled": item.count === 0,
        });

        return (
            <label key={item.slug} className={itemClasses}>
                <div className="filter-list__input input-check">
                    <span className="filter-list__title">{item.label}</span>
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            value={item.id}
                            checked={
                                filterValues && filterValues[value.code]
                                    ? filterValues[value.code].toString().includes(item.id)
                                    : ""
                            }
                            disabled={item.count === 0}
                            onChange={(e) => handleChange(e, item.id, value.code)}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </div>
            </label>
        );
    });

    return (
        <div className="filter-list">
            <div
                className="widget__title title-arrow-flex"
                // onClick={(e) => {
                //     setIsOpen(!isOpen);
                // }}
            >
                {/* {props.title} */}
                <FormattedMessage id={props.title.toLowerCase().replace(/ /g, ".")} defaultMessage={props.title} />
                {/* <ArrowDown className={isOpen ? "d-block rott_fms" : "d-block"} /> */}
            </div>
            <div className="dropdown-group active filter-list__list dropdown-group">{itemsList}</div>
        </div>
    );
}

FilterCheck.propTypes = {
    /**
     * Filter object.
     */
    data: PropTypes.object,
    /**
     * Value.
     */
    value: PropTypes.arrayOf(PropTypes.string),
    /**
     * Change value callback.
     */
    onChangeValue: PropTypes.func,
};

export default FilterCheck;
