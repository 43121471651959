// react
import React from "react";
import { Link } from "react-router-dom";
// data stubs
import theme from "../../data/theme";
import { ArrowDown, LogoSvg } from "../../svg";
import SocialLinks from "../shared/SocialLinks";
import { FormattedMessage } from "react-intl";

export default function FooterContacts(props) {
    const links = [
        { title: <FormattedMessage id="topbar.phone1" defaultMessage="374 93 99-10-93" />, url: "" },
        { title: <FormattedMessage id="topbar.phone2" defaultMessage="+374 10 35-11-00" />, url: "" },
    ];

    const linksList = links.map((item, index) => (
        <a key={index} href={`tel:${item.title.props.defaultMessage}`}>
            {item.title}
        </a>
    ));
    return (
        <div className="footer-contacts">
            <div
                className="footer_contact_text_fms"
                onClick={() => (props.size < 768 ? props.openFunc("contacts") : "")}
            >
                <FormattedMessage id="foot.contactinformation" defaultMessage="Կոնտակտային տվյալներ" />
                <ArrowDown className={props.open ? "d-block d-md-none rott_fms" : "d-block d-md-none"} />
            </div>
            {props.open ? (
                <ul className="footer-contacts__contacts">
                    <li>
                        <FormattedMessage id="foot.address" defaultMessage="Հասցե՝ Ք․ Երևան, Կոմիտաս 49" />
                    </li>
                    <li>
                        <FormattedMessage id="foot.phone" defaultMessage="Հեռախոսահամար՝ +374 94 255555" />
                    </li>
                    <li>
                        <FormattedMessage id="foot.email" defaultMessage="Էլ հասցե՝ hiphone@gmail.com" />
                    </li>
                </ul>
            ) : (
                ""
            )}
        </div>
    );
}
