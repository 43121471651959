// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import { useEffect } from "react";
import { url } from "../../helper";
import { useSelector } from "react-redux";
import { useState } from "react";
import BlockLoader from "../blocks/BlockLoader";
import { FormattedMessage } from "react-intl";

export default function AccountPageOrderDetails({ id }) {
    const customer = useSelector((state) => state.customer);
    const [order, setOrder] = useState();
    const locale = useSelector((state) => state.locale);

    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;

        if (id.orderId && customer.token) {
            fetch(url + `/api/orders?id=${id.orderId}&token=${customer.token}&locale=${locale}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data) {
                        setOrder(res.data[0]);
                    }
                })
                .catch((err) => console.error(err));
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [id.orderId]);

    if (!order) {
        return <BlockLoader />;
    }

    const items = order.items.map((item, index) => {
        return (
            <tr key={index}>
                <td>{item.product.name}</td>
                <td>{item.formated_price}</td>
                <td>{item.qty_ordered}</td>
                <td>{new Date(order.created_at).toLocaleString().slice(0, -3)}</td>
            </tr>
        );
    });

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>
            {console.log(order)}
            <div className="card">
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/account/orders" className="btn btn-xs btn-secondary">
                            <FormattedMessage id="backToList" defaultMessage="Վերադառնալ ցուցակին" />
                        </Link>
                    </div>
                    <h5 className="order-header__title">
                        <FormattedMessage id="order" defaultMessage="Պատվեր" /> #{order.id}
                    </h5>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="product" defaultMessage="Ապրանք" />{" "}
                                    </th>
                                    <th>
                                        <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="qty" defaultMessage="Քանակ" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="date" defaultMessage="Ամսաթիվ" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">{items}</tbody>

                            <tfoot>
                                <tr>
                                    <th>
                                        <FormattedMessage id="total" defaultMessage="Ընդհանուր" />
                                    </th>
                                    <td>{order.formated_sub_total}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">
                                <FormattedMessage id="shippingAddress" defaultMessage="Աոաքման Հասցե" />
                            </div>

                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="streetAddress" defaultMessage="Հասցե:" />
                                </div>
                                {order.shipping_address.address1}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="phone" defaultMessage="Հեռախոսահամար։" />
                                </div>
                                <div className="address-card__row-content">{order.shipping_address.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="email" defaultMessage="Էլ․ Հասցե:" />
                                </div>
                                <div className="address-card__row-content">{order.shipping_address.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3 pay-adress-fms">
                    <div className="card address-card address-card--featured">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">
                                <FormattedMessage id="billingAddress" defaultMessage="Վճարման հասցեն" />
                            </div>

                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="streetAddress" defaultMessage="Հասցե:" />
                                </div>
                                {order.billing_address.address1}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="phone" defaultMessage="Հեռախոսահամար։" />
                                </div>
                                <div className="address-card__row-content"> {order.billing_address.phone}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">
                                    <FormattedMessage id="email" defaultMessage="Էլ․ Հասցե:" />
                                </div>
                                <div className="address-card__row-content"> {order.billing_address.email}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
