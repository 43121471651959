// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import shopApi from "../../api/shop";
import { url } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import ProductAttributes from "../shared/ProductAttributes";

function ShopPageProduct(props) {
    const selectedData = useSelector((state) => state.locale);
    const customer = useSelector((state) => state.customer);
    const { productSlug, layout, sidebarPosition, parentId } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [crossProducts, setCrossProducts] = useState([]);

    // Load product.
    useEffect(() => {
        let canceled = false;
        setIsLoading(true);

        // shopApi.getProductBySlug(parentId == -1 ? productSlug : parentId, { lang: selectedData }).then((product) => {
        //     if (canceled) {
        //         return;
        //     }
        //     setProduct(product);
        //     setIsLoading(false);
        // });

        shopApi.getProductBySlug(productSlug ? productSlug : parentId, { lang: selectedData }).then((product) => {
            if (canceled) {
                return;
            }
            setProduct(product);
            setIsLoading(false);
        });
        return () => {
            canceled = true;
        };
    }, [productSlug, selectedData]);

    // Load related products.
    useEffect(() => {
        let canceled = false;
        if (product)
            shopApi.getRelatedProducts(product.data.cats[0], { lang: selectedData }, { limit: 8 }).then((products) => {
                if (canceled) {
                    return;
                }

                setRelatedProducts(products.data.filter((elem, index) => index < 7));
            });

        return () => {
            canceled = true;
        };
    }, [product]);

    // Load crossProducts  .
    useEffect(() => {
        let canceled = false;

        // if (layout !== 'sidebar') {
        //     setCrossProducts([]);
        // } else {
        shopApi.getCrossSaleProducts(productSlug, { lang: selectedData }, { limit: 5 }).then((result) => {
            if (canceled) {
                return;
            }

            // setCrossProducts(result.data);
        });
        // }

        // return () => {
        //     canceled = true;
        // };
    }, [selectedData]);

    if (isLoading) {
        return <BlockLoader />;
    }

    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: url.home() },
        { title: <FormattedMessage id="shop" defaultMessage="Խանութ" />, url: url.catalog() },
        { title: product.data.name, url: url.product(product.data) },
    ];

    const related = <FormattedMessage id="relatedProducts" defaultMessage="Հարակից ապրանքներ" />;
    const similar = <FormattedMessage id="similarProducts" defaultMessage="Նմանատիպ ապրանքներ" />;

    let content;

    content = (
        <React.Fragment>
            <div className="block">
                <div className="container_fm">
                    <Product
                        product={product}
                        layout={layout}
                        customer={customer}
                        productSlug={props.productSlug}
                        parentId={props.parentId}
                    />
                    {/* <ProductTabs /> */}
                </div>
            </div>
            <div className="prod_fms">
                <ProductAttributes products={[product.data]} />
            </div>

            {relatedProducts.length > 0 && (
                <BlockProductsCarousel customer={customer} title={related} layout="grid-5" products={relatedProducts} />
            )}

            {/* {relatedProducts.length > 0 && (
                    <BlockProductsCarousel
                        customer={customer}
                        title={similar}
                        layout="grid-5"
                        products={crossProducts}
                    />
                )} */}
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${product.name} — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};

export default ShopPageProduct;
