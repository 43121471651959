// react
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import YouTube from "react-youtube";
import YoutubeEmbed from "./YoutubeEmbed";
function ProductAttributes(props) {
    const { products } = props;
    const [attributeRows, SetAttributeRows] = useState();
    const [specification, SetSpecification] = useState("");
    const [state, setState] = useState("");
    const [bgcolor, setBgcolor] = useState("white");
    const [secbgcolor, setSecbgcolor] = useState({ background: "white", color: "black" });
    let content;
    let attrName = [];
    let attribute = [];
    if (products) {
        products.forEach((e) => {
            for (let attrDis in e.group) {
                let fill = attribute.filter((el) => el == attrDis);

                if (!fill.length) attribute.push(attrDis);
            }
        });

        products.forEach((e) => {
            attribute.forEach((el) => {
                if (e.group[el] && e.group[el].length) {
                    e.group[el].forEach((item) => {
                        if (item.length) {
                            let fill = attrName.filter((el) => el.value == item[0].admin_name);
                            if (!fill.length) attrName.push({ key: el, value: item[0].admin_name });
                        }
                    });
                }
            });
        });

        let vidio = products[0].video_link;

        const onClick = (e) => {
            SetAttributeRows(
                <>
                    {attribute.length
                        ? attribute.map((el) => {
                              return (
                                  <>
                                      <div className="attr-title_fm">
                                          <FormattedMessage
                                              id={el.toLowerCase().replace(/ /g, ".")}
                                              defaultMessage={el}
                                          />
                                      </div>
                                      {attrName.length
                                          ? attrName.map((es) => {
                                                return el == es.key && es.value != undefined ? (
                                                    <div className="attr-information_fm">
                                                        <div>{es.value}</div>
                                                        {products.map((e) => {
                                                            return e.group && e.group[el].length && e.group[el]
                                                                ? e.group[el].map((item) => {
                                                                      return item.length &&
                                                                          item[0].admin_name == es.value ? (
                                                                          <div>{item[0].name}</div>
                                                                      ) : (
                                                                          ""
                                                                      );
                                                                  })
                                                                : "";
                                                        })}
                                                    </div>
                                                ) : (
                                                    ""
                                                );
                                            })
                                          : ""}
                                  </>
                              );
                          })
                        : ""}
                </>
            );
            setBgcolor("#157dff");
            setSecbgcolor("white");
            SetSpecification("");
        };

        const onClicked = (e) => {
            SetSpecification(
                <>
                    <div className="specification-container">
                        <h1>{products[0].name}</h1>
                        <YoutubeEmbed embedId={vidio} />
                    </div>
                </>
            );
            setBgcolor("white");
            SetAttributeRows("");
            setSecbgcolor("#157dff");
        };

        let setSeccolor = () => {
            if (secbgcolor == "#157dff") {
                return "white";
            }
        };
        let setcolor = () => {
            if (bgcolor == "#157dff") {
                return "white";
            }
        };
        content = (
            <>
                <div className="info-buttnos d-flex">
                    <div className="description-buttons">
                        <button onClick={onClick} style={{ background: bgcolor, color: setcolor() }}>
                            <FormattedMessage id="description" defaultMessage="Description" />
                        </button>
                    </div>
                    <div className="specification-buttons">
                        <button onClick={onClicked} style={{ background: secbgcolor, color: setSeccolor() }}>
                            <FormattedMessage id="specification" defaultMessage="Specification" />
                        </button>
                    </div>
                </div>
                <div className="compare_fm">
                    <div className="info-attr_fm">
                        {attributeRows}
                        {specification}
                        {attributeRows || specification ? (
                            attributeRows && specification
                        ) : (
                            <>
                                {attribute.length
                                    ? attribute.map((el) => {
                                          return (
                                              <>
                                                  <div className="attr-title_fm">
                                                      <FormattedMessage
                                                          id={el.toLowerCase().replace(/ /g, ".")}
                                                          defaultMessage={el}
                                                      />
                                                  </div>
                                                  {attrName.length
                                                      ? attrName.map((es) => {
                                                            return el == es.key && es.value != undefined ? (
                                                                <div className="attr-information_fm">
                                                                    <div>{es.value}</div>
                                                                    {products.map((e) => {
                                                                        return e.group &&
                                                                            e.group[el].length &&
                                                                            e.group[el]
                                                                            ? e.group[el].map((item) => {
                                                                                  return item.length &&
                                                                                      item[0].admin_name == es.value ? (
                                                                                      <div>{item[0].name}</div>
                                                                                  ) : (
                                                                                      ""
                                                                                  );
                                                                              })
                                                                            : "";
                                                                    })}
                                                                </div>
                                                            ) : (
                                                                ""
                                                            );
                                                        })
                                                      : ""}
                                              </>
                                          );
                                      })
                                    : ""}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
    return <div className="container_fm">{content}</div>;
}

export default ProductAttributes;
