// react
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ArrowDown } from "../../svg";
import { url } from "../../helper";

export default function FooterLinks(props) {
    const selectedData = useSelector((state) => state.locale);
    const [footLinks, SetFootLinks] = useState();
    let linksList;
    useEffect(() => {
        fetch(`${url}/api/cms/menus?locale=${selectedData}`)
            .then((response) => response.json())
            .then((res) => {
                SetFootLinks(res.data);
            });
    }, [selectedData]);

    if (footLinks) {
        linksList = footLinks.map((item, index) => {
            return (
                <div className="nav-links__item" activeClassName="is-active">
                    <Link to={`/page/${item.page_id}`}>{item.name}</Link>
                </div>
            );
        });
    }

    return (
        <div>
            <div className="footer_contact_text_fms" onClick={() => (props.size < 768 ? props.openFunc("links") : "")}>
                <FormattedMessage id="foot.aboutus" defaultMessage="Մեր մասին" />
                <ArrowDown className={props.open ? "d-block d-md-none rott_fms" : "d-block d-md-none"} />
            </div>
            {props.open ? <div className="cart_bank_fms">{linksList}</div> : ""}
        </div>
    );
}

FooterLinks.propTypes = {
    /** widget title */
    title: PropTypes.node.isRequired,
    /** array of links */
    items: PropTypes.array,
};

FooterLinks.defaultProps = {
    items: [],
};
