// react
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import ProductCardShopSearch from '../shared/ProductCardShopSearch';


function ShopPageSearch(props){
   let productData = JSON.parse(localStorage.getItem('searchProductsFm'))
   const customer = useSelector(state => state.customer);
   let smalls;

   if ( productData) {
      const productsList =  productData.map((product, index) => {
         // console.log(product, "ooooooooooooo")
          return <div key={index} className="product_fms">
                  <div className='block-products__list-item'>
                      <ProductCardShopSearch product={product}  customer={customer}  />
                  </div>
          </div>
      });
      
      smalls = (
         <div className={props.style? `block-products__list ${props.style}`: `block-products__list`}>
              {productsList}

          </div>
      );
      
   }
  

   return(
      <div className="search-prod_fm">
         <div className="container_fm">
            <div className="search-product_fm">
               {smalls}
            </div>
         </div>
      </div>
   )
};

export default ShopPageSearch;

ShopPageSearch.propTypes = {
   products: PropTypes.array, 
   
};
