// react
import React, { useState } from "react";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";
import ToTop from "./ToTop";
import { FormattedMessage } from "react-intl";

import FidemLogo from "../../images/FidemLogo.png";

export default function Footer() {
    const [contacts, SetContacts] = useState(false);
    const [links, SetLinks] = useState(false);
    const [shipp, SetShipp] = useState(false);

    const openFunc = (type) => {
        if (type == "contacts") {
            SetContacts(true);
            SetLinks(false);
            SetShipp(false);
            if (contacts == true) SetContacts(false);
        }
        if (type == "links") {
            SetLinks(true);
            SetContacts(false);
            SetShipp(false);
            if (links == true) SetLinks(false);
        }
        if (type == "shipp") {
            SetShipp(true);
            SetContacts(false);
            SetLinks(false);
            if (shipp == true) SetShipp(false);
        }
    };

    return (
        <div className="site-footer">
            <div className="container_fm">
                <div className="foot_content_fms">
                    <div>
                        <FooterContacts
                            size={window.innerWidth}
                            openFunc={openFunc}
                            open={window.innerWidth < 768 ? contacts : true}
                        />
                    </div>
                    <div>
                        <FooterLinks
                            size={window.innerWidth}
                            title="Information"
                            openFunc={openFunc}
                            open={window.innerWidth < 768 ? links : true}
                        />
                    </div>
                    <div>
                        <FooterNewsletter
                            size={window.innerWidth}
                            openFunc={openFunc}
                            open={window.innerWidth < 768 ? shipp : true}
                        />
                    </div>
                    <img src="/images/bank.svg" alt="" className="bank_fms d-md-none d-none" width="200" />
                </div>
            </div>

            <ToTop />
        </div>
    );
}
