// react
import React, { useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import { cartAddItem } from "../../store/cart";
import { Wishlist16Svg, FM16Svg, WishHeart, Bedge3, Bedge2, Bedge1, Bag6Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { url } from "../../services/utils";
import { wishlistAddItem } from "../../store/wishlist";

import { urlLink } from "../../helper";
import { FormattedMessage } from "react-intl";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ProductCardShopSearch(props) {
    const { customer, product, layout, cartAddItem, wishlistAddItem, compareAddItem } = props;

    const wish = useSelector((state) => state.wishlist);
    const compare = useSelector((state) => state.compare);
    const [wishUpdate, setWish] = useState();
    const [products, getProducts] = useState();

    useEffect(() => {
        if (wish.length) {
            if (wish.filter((e) => e.id == product.id).length) Object.assign(product, { wishlist: true });
            else {
                Object.assign(product, { wishlist: false });
            }
        } else {
            Object.assign(product, { wishlist: false });
        }
        setWish("");
    }, [wish]);

    useEffect(() => {
        if (wish.length) {
            if (wish.filter((e) => e.id == product.id).length) Object.assign(product, { wishlist: true });
            else {
                Object.assign(product, { wishlist: false });
            }
        } else {
            Object.assign(product, { wishlist: false });
        }
        setWish("");
    }, [wish]);

    const selectedData = useSelector((state) => state.locale);
    const cartToken = useSelector((state) => state.cartToken);

    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });

    let badges = [];
    let image;
    let price;
    let features;
    let priceBege = Math.round(`${100 - (parseInt(product.special_price) * 100) / parseInt(product.price)}`);
    let priceSale = `-${priceBege} %`;

    // Sale
    if (product.special_price) {
        badges.push(
            <div className="badges-fms pointed io ">
                <span>{priceSale}</span>
            </div>
        );
    } else if (product.special_price && product.new === 1) {
        badges.push(
            <div className="jp">
                <div className="badges-fms pointed io ">
                    <span>{priceSale}</span>
                </div>
                <Bedge1 />
            </div>
        );
    } else if (product.special_price && product.new === 1 && product.qty === 0) {
        badges.push(
            <div className="jp">
                <div className="badges-fms pointed io ">
                    <span>{priceSale}</span>
                </div>
                <Bedge1 />
                <Bedge3 />
            </div>
        );
    }
    //New
    if (product.new === 1) {
        badges.push(
            <div className="badges-fms">
                <Bedge1 />
            </div>
        );
    }
    //SoldOut
    if (product.qty === 0) {
        badges.push(
            <div className="badges-fms">
                <Bedge3 />
            </div>
        );
    }

    if (product.image && product.image.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <div className="wish_fms">
                    {/* <div className="in_stock_fms"><FormattedMessage id="addToCart1" defaultMessage="Առկա է" /></div> */}
                    <div className="wish_and_fms">
                        <AsyncAction
                            action={() => {
                                return wishlistAddItem(
                                    product,
                                    customer.token ? customer.token : false,
                                    product.wishlist
                                ).then((e) => {
                                    if (e == false)
                                        toast.success(
                                            <FormattedMessage
                                                id="please.login.toast"
                                                defaultMessage="`Please login or register in order to add to favorites.`"
                                            />
                                        );
                                });
                            }}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames(
                                        "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                        {
                                            "btn-loading": loading,
                                        }
                                    )}
                                >
                                    {wishUpdate || !product.wishlist ? <Wishlist16Svg /> : <WishHeart />}
                                </button>
                            )}
                        />

                        <AsyncAction
                            action={() => compareAddItem(product)}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    disabled={window.screen["width"] < 991 ? compare.length == 2 : compare.length == 3}
                                    className={classNames(
                                        "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                        {
                                            "btn-loading": loading,
                                        }
                                    )}
                                >
                                    <FM16Svg />
                                </button>
                            )}
                        />
                    </div>
                </div>
                <Link to={url.product(product)} className="product-image__body">
                    <img
                        className="product-image__img"
                        src={`${urlLink}/storage/` + product.image || product.image[0].medium_image_url}
                        alt=""
                    />
                </Link>
            </div>
        );
    }

    if (product.special_price) {
        price = (
            <div className="product-card__prices-fms">
                <span className="product-card__old-price">
                    <Currency value={parseInt(product.price)} />
                </span>{" "}
                <span className="product-card__new-price">
                    <Currency value={parseInt(product.special_price)} />
                </span>
            </div>
        );
    } else {
        price = (
            <div className="product-card__prices">
                <Currency value={parseInt(product.price)} />
            </div>
        );
    }

    if (product.attributes && product.attributes.length) {
        features = (
            <ul className="product-card__features-list">
                {product.attributes
                    .filter((x) => x.featured)
                    .map((attribute, index) => (
                        <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(", ")}`}</li>
                    ))}
            </ul>
        );
    }

    return (
        <React.Fragment>
            {product.id === -1 ? (
                <div>
                    <FormattedMessage id="SorryNothingFoundFor" defaultMessage="Sorry, nothing found for" />
                </div>
            ) : (
                <div className={containerClasses}>
                    {badges}
                    {image}
                    <div className="d-flex product-card__info">
                        <div className="product-card__name">
                            <Link to={url.product(product)}>{product.name}</Link>
                        </div>
                    </div>
                    <div className="product-card__actions_fms">
                        {/* <div className="product-card__availability">
                        <FormattedMessage id="availability" defaultMessage="Հասանելիություն" /> :
                    <span className="text-success"><FormattedMessage id="inStock" defaultMessage="Առկա է" /></span>
                    </div> */}
                        {price}
                        <div className="product-card__buttons">
                            <span className="add-cart-button-fms">
                                <FormattedMessage id="add" defaultMessage="Add to cart" />
                            </span>
                            <AsyncAction
                                action={() => {
                                    const newProduct = {
                                        ...product,
                                        id: product.product_id,
                                    };
                                    return cartAddItem(
                                        newProduct,
                                        [],
                                        1,
                                        cartToken.cartToken,
                                        customer.token ? customer.token : null
                                    );
                                }}
                                render={({ run, loading }) => (
                                    <React.Fragment>
                                        <button
                                            type="button"
                                            onClick={run}
                                            className={classNames("product-card__addtocart_fms", {
                                                "btn-loading": loading,
                                            })}
                                        >
                                            <Bag6Svg />

                                            {/* <FormattedMessage id="buy" defaultMessage="Գնել" /> */}
                                        </button>
                                        {/* <button
                                        type="button"
                                        onClick={run}
                                        className={classNames('product-card__addtocart_fms product-card__addtocart--list', {
                                            'btn-loading': loading,
                                        })}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26.65" height="20" viewBox="0 0 26.65 20">
                                            <g id="Сгруппировать_117" data-name="Сгруппировать 117" transform="translate(17135.776 18264.001)">
                                                <g id="Сгруппировать_34" data-name="Сгруппировать 34" transform="translate(-17135.777 -18264.002)" opacity="0.95">
                                                    <g id="Сгруппировать_23" data-name="Сгруппировать 23" transform="translate(0.001 0.001)">
                                                        <path id="Icon_open-cart" data-name="Icon open-cart" d="M-.363,1.931a1.688,1.688,0,0,0,1.933,1.4h5l.3.833L8.237,8.331,9.6,12.5a1.351,1.351,0,0,0,1.133.833H22.4a1.309,1.309,0,0,0,1.133-.833l2.7-8.333c.133-.433-.067-.833-.533-.833H10.9L9.637.931A1.667,1.667,0,0,0,8.17,0H1.5a1.667,1.667,0,0,0-.3,0C1.137-.006-.511,1.011-.363,1.931Z" transform="translate(0.373 0.009)" fill="#fff" />
                                                        <circle id="Эллипс_8" data-name="Эллипс 8" cx="1.852" cy="1.852" r="1.852" transform="translate(11.1 16.296)" fill="#fff" />
                                                        <circle id="Эллипс_9" data-name="Эллипс 9" cx="1.852" cy="1.852" r="1.852" transform="translate(19.248 16.296)" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>

                                        <FormattedMessage id="addToCart1" defaultMessage="Գնել" />
                                    </button> */}
                                    </React.Fragment>
                                )}
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

ProductCardShopSearch.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf(["grid-sm", "grid-nl", "grid-lg", "list", "horizontal"]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    quickviewOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardShopSearch);
