// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
// data stubs
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useState } from 'react';

import Content from './Content'
import { useHistory } from 'react-router-dom';
import {url} from '../../helper';


const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 379,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

function SiteCustomPage(props) {
    const history = useHistory();
    const selectedData = useSelector(state => state.locale)
    const { id } = props
    const [content, setContent] = useState();
 
    useEffect(() => {
        let canceled = false; 
        fetch(`${url}/api/cms/pages?id=${id.pageID}&locale=${selectedData}`)
            .then(response => response.json())
            .then(res => {           
                if(res && res.data && res.data.length){
                    setContent(res.data[0])
                }else{
                    history.push('/');
                }

            })

    }, [props,selectedData])

 

    return (
       <React.Fragment> 

           <Helmet>
                <title>{`Custom page`}</title>
                <meta name="description" content="Custom description" />
            </Helmet>  
            <div className="container_fm">
                <div className="title_account_fms">{content? content.page_title: ''}</div>
                <div dangerouslySetInnerHTML={{__html: content? content.html_content: ''}}></div>  
            </div>

        </React.Fragment>
    );

     
}

export default SiteCustomPage;
