import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import React from "react";
import { COMPARE_ADD_ITEM, COMPARE_REMOVE_ITEM } from "./compareActionTypes";
import { CheckToastSvg } from "../../svg";

export function compareAddItemSuccess(product, type = false) {
    let compareMeassage = (
        <span className="d-flex chek-fms">
            <CheckToastSvg />
            <FormattedMessage id="product.add.compare" defaultMessage={`Product "${product.name}" added to compare!`} />
        </span>
    );
    if (!type) toast.success(compareMeassage);

    return {
        type: COMPARE_ADD_ITEM,
        product,
    };
}

export function compareRemoveItemSuccess(productId) {
    return {
        type: COMPARE_REMOVE_ITEM,
        productId,
    };
}

export function compareAddItem(product, type = false) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                resolve("ssss");
                dispatch(compareAddItemSuccess(product, type));
            }, 0);
        });
}

export function compareRemoveItem(productId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(compareRemoveItemSuccess(productId));
                resolve();
            }, 500);
        });
}
