// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// application
import Footer from "./footer";
import Header from "./header";
import TopbarT from "./header/TopbarT";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import CreepingLine from "../components/header/CreepingLine";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import AccountForgetPassword from "./account/AccountForgetPassword";
import AccountResetPassword from "./account/AccountResetPassword";
import BlogPageCategory from "./blog/BlogPageCategory";
import BlogPagePost from "./blog/BlogPagePost";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageProduct from "./shop/ShopPageProduct";

import SitePageNotFound from "./site/SitePageNotFound";

import SiteCustomPage from "./site/SiteCustomPage";
import ThankPage from "./site/ThankPage";

import { useSelector, useDispatch } from "react-redux";
import { url } from "../../src/helper";
// data stubs
import theme from "../data/theme";
import jwt_decode from "jwt-decode";
import ReactGA from "react-ga";
import ShopPageCompare from "./shop/ShopPageCompare";
import ShopPageSearch from "./shop/ShopPageSearch";

function Layout(props) {
    const { match, headerLayout, homeComponent } = props;
    const customer = useSelector((state) => state.customer);
    const cartToken = useSelector((state) => state.cartToken);
    const dispatch = useDispatch();

    const [googleCode, setGoogleCode] = useState("");

    useEffect(() => {
        if (googleCode) {
            fetch(`${url}/api/googlecode`)
                .then((res) => res.json)
                .then((responce) => {
                    if (responce.code) {
                        ReactGA.initialize(responce.code);
                        ReactGA.pageview(window.location.pathname);
                        setGoogleCode(responce.code);
                    }
                });
        }
    }, [googleCode]);

    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;

        if (cartToken.cartToken === "") {
            fetch(url + "/api/checkout/cart/token", { single: single })
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.api_token) {
                        dispatch({ type: "CART_TOKEN", payload: res.api_token });
                    }
                })
                .catch((err) => console.error(err));
        }
        return function cleaup() {
            abortController.abort();
        };
    }, [cartToken.cartToken === ""]);

    useEffect(() => {
        const abortController = new AbortController();
        const single = abortController.single;

        if (customer.token) {
            const { exp } = jwt_decode(customer.token);

            if (Date.now() >= exp * 1000) {
                dispatch({ type: "AUTHENTICATED", payload: false });
                dispatch({ type: "CUSTOMER_TOKEN", payload: "" });
                dispatch({ type: "CUSTOMER_NAME", payload: false });
            }
        }

        return function cleaup() {
            abortController.abort();
        };
    }, [customer.token]);
    useEffect(() => {
        if (customer.token)
            fetch(`${url}/api/wishlist?token=${customer.token}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.data.length) {
                        let arr = [];
                        res.data.forEach((element) => {
                            arr.push(element.product);
                        });
                        dispatch({ type: "WISH_LIST", payload: arr });
                    } else {
                        dispatch({ type: "WISH_LIST", payload: [] });
                    }
                });
        else {
            dispatch({ type: "WISH_LIST", payload: [] });
        }
    }, [customer.token]);
    return (
        <React.Fragment>
            <ToastContainer autoClose={2000} />
            <MobileMenu />

            <div className="site">
                <CreepingLine />
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>
                <TopbarT />
                <header className="site__header desktop">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/catalog" />
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />

                        <Route
                            exact
                            path="/shop/catalog/search"
                            render={(props) => (
                                <ShopPageSearch {...props} columns={3} viewMode="grid" sidebarPosition="start" />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                    catID={props.match.params.catID}
                                />
                            )}
                        />
                        {/* <Route
                            exact
                            path="/shop/products/:productSlug/:parentId"
                            render={(props) => {


                                return (
                                    <ShopPageProduct
                                        {...props}
                                        layout="standard"
                                        productSlug={props.match.params.productSlug}
                                        parentId={props.match.params.parentId}
                                    />
                                )
                            }}
                        /> */}

                        <Route
                            exact
                            path="/shop/product/:productSlug"
                            render={(props) => {
                                return (
                                    <ShopPageProduct
                                        {...props}
                                        layout="standard"
                                        productSlug={props.match.params.productSlug}
                                        parentId={props.match.params.parentId}
                                    />
                                );
                            }}
                        />
                        <Route exact path="/shop/cart" component={PageCart} />
                        <Route exact path="/shop/checkout" component={PageCheckout} />
                        <Route exact path="/shop/checkout/success" component={ShopPageOrderSuccess} />
                        <Route exact path="/shop/wishlist" component={PageWishlist} />
                        <Route exact path="/shop/compare" component={ShopPageCompare} />
                        <Route
                            exact
                            path="/blog"
                            render={(props) => <BlogPageCategory {...props} layout="grid" sidebarPosition="end" />}
                        />

                        <Route
                            exact
                            path="/blog/:blogID"
                            render={(props) => (
                                <BlogPagePost
                                    id={props.match.params}
                                    {...props}
                                    layout="classic"
                                    sidebarPosition="end"
                                />
                            )}
                        />

                        {/*
                        // Account
                        */}
                        {!customer.authenticated && !customer.token ? (
                            <Route exact path="/account/login" component={AccountPageLogin} />
                        ) : (
                            <Route path="/account" component={AccountLayout} />
                        )}
                        {/*
                        // Site
                        */}
                        <Route exact path="/forget/password" component={AccountForgetPassword} />
                        <Route
                            exact
                            path="/customer/reset-password/:key"
                            render={(props) => <AccountResetPassword {...props} />}
                        />
                        <Route
                            exact
                            path="/page/:pageID"
                            render={(props) => <SiteCustomPage {...props} id={props.match.params} />}
                        />
                        {/* <Redirect exact from="/site" to="/site/about-us" />
                        <Route exact path="/site/about-us" component={SitePageAboutUs} />
                        <Route exact path="/site/components" component={SitePageComponents} />
                        <Route exact path="/site/contact-us" component={SitePageContactUs} />
                        <Route exact path="/site/contact-us-alt" component={SitePageContactUsAlt} />
                        <Route exact path="/site/not-found" component={SitePageNotFound} />
                        <Route exact path="/site/faq" component={SitePageFaq} />
                        <Route exact path="/site/terms" component={SitePageTerms} />
                        <Route exact path="/site/typography" component={SitePageTypography} /> */}
                        <Route path="/thanks" {...props} component={ThankPage} />
                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

export default Layout;
