
import Visa from '../images/visa.png';

export default [
    
    {
        key: 'cashondelivery',
        title:{

            en:'Cash on delivery',
            hy:'Վճարել տեղում'
        }, 
        
        img:''
    },
    {
        key: 'ameria_vpos',
        title: {

            en:'Credit Cart',
            hy:'Կրեդիտ քարտ'
        },
        
        img:Visa
    },
];
