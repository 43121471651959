import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";

export default function Register(props) {
    const intialValues = { email: "", password: "", password_confirmation: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [errMsg, setErrMsg] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const locale = useSelector((state) => state.locale);
    const submitForm = () => {
        let options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/customer/register?locale=${locale}`, options)
            .then((responce) => responce.json())
            .then((res) => {
                if (res.message == "Your account has been created successfully.") {
                    props.close("reg", false);
                    props.clicked("login", true);
                } else {
                    if (res.email.length) {
                        setErrMsg(res.email[0]);
                    }

                    if (res.password.length) {
                        setErrMsg(res.password[0]);
                    }
                }
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (!values.password_confirmation || values.password != values.password_confirmation) {
            errors.password_confirmation = "Passwords do not match";
        }

        if (!values.email || !regex.test(values.email)) {
            errors.email = "Invalid email format";
        }

        if (!values.password || values.password.length < 6) {
            errors.password = "Password must be more than 6 characters";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            <div className="logn_title_fm">
                <FormattedMessage id="reg.letsGetStarted" defaultMessage="Գրանցում" />
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div>
                    {formErrors.first_name && (
                        <span className="error">
                            <FormattedMessage id="account.error.required" defaultMessage={formErrors.first_name} />
                        </span>
                    )}
                </div>

                <div className={formErrors.email && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="login.email.address" defaultMessage="Էլ․ հասցե" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.email ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="text"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                        <span className="login-error-fms">{formErrors.email}</span>
                    </label>
                </div>
                <div className={formErrors.password && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="topbar.password" defaultMessage="Գաղտնաբառ" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.password ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="password"
                            name="password"
                            value={formValues.password}
                            onChange={handleChange}
                        />
                        <span className="login-error-fms">{formErrors.password}</span>
                    </label>
                </div>

                <div className={formErrors.password_confirmation && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="reg.confirm.password" defaultMessage="Հաստատել գաղտնաբառը" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.password_confirmation ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="password"
                            name="password_confirmation"
                            value={formValues.password_confirmation}
                            onChange={handleChange}
                        />
                        <span className="login-error-fms">{formErrors.password_confirmation}</span>
                    </label>
                </div>
                {errMsg ? <div style={{ color: "red" }}>{errMsg}</div> : ""}
                <button>
                    <FormattedMessage id="reg.sign.up" defaultMessage="Գրանցվել" />
                </button>
            </form>
            {/* <div className="sign_up_fm">
                <div className="singup_link_fm">
                    <FormattedMessage id="reg.alreadyHaveAnAccount" defaultMessage="Already have an account?" />
                    <span   style={{color: "#66C3D0", textDecoration: "underline"}}  onClick={() => { props.close('reg', false); props.clicked('login', true) }}><FormattedMessage id="reg.log.in" defaultMessage="Log In" /></span>
                </div>
            </div> */}
        </div>
    );
}
