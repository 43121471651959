import React from "react";

import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";

export default function ForgotPassword(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const intialValues = { email: "" };
    const [formValues, setFormValues] = useState(intialValues);
    const [formErrors, setFormErrors] = useState({});
    const [doneMSG, setdoneMSG] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitForm = () => {
        let option = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
        };

        fetch(`${url}/api/customer/forget-password`, option)
            .then((responce) => responce.json())
            .then((res) => {
                setdoneMSG(res);
            })
            .catch((err) => console.error(err));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
    };

    const validate = (values) => {
        let errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "This field is required";
        } else if (!regex.test(values.email)) {
            errors.email = "Invalid email format";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmitting) {
            submitForm();
        }
    }, [formErrors]);

    return (
        <div className="login_content_fm">
            <div className="logn_title_fm">
                <FormattedMessage id="login.welcome.back" defaultMessage="Վերականգնել գաղտնաբառը" />
            </div>
            <form action="" onSubmit={handleSubmit}>
                <div className={formErrors.email && "input-error_fm"}>
                    <label>
                        <div className="text-left">
                            <span>
                                <FormattedMessage id="login.email.address" defaultMessage="Էլ․ հասցե" />
                            </span>
                            <span style={{ color: "red" }}>*</span>
                        </div>
                        <input
                            className={formErrors.email ? `login_inp_fm border_red` : `login_inp_fm`}
                            type="text"
                            name="email"
                            id="email"
                            value={formValues.email}
                            onChange={handleChange}
                        />
                    </label>
                </div>
                <div>
                    {doneMSG ? <FormattedMessage id="emailed.reset" defaultMessage={doneMSG ? doneMSG : ""} /> : null}
                </div>
                {doneMSG ? null : (
                    <button>
                        <FormattedMessage id="login.getCode" defaultMessage="Ստանալ կոդ" />
                    </button>
                )}
            </form>
            {/* <div className="sign_up_fm">
                <div className="singup_link_fm" onClick={() => { props.close('forgot', false); props.clicked('login', true) }}>
                        Already have an account?  <span>Sign In</span>
                </div>
            </div> */}
        </div>
    );
}
