import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import Root from './components/Root';
import store from './store';

// styles
import 'slick-carousel/slick/slick.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-input-range/lib/css/index.css';
import './scss/style.scss';
// import { hydrate, render } from "react-dom";
// const App=(

//     <Provider store={store}>
//          <Root />
//     </Provider>
// )

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(App, rootElement);
// } else {
//   render(App, rootElement);
// }
ReactDOM.render((
    // eslint-disable-next-line react/jsx-filename-extension
    
        <Provider store={store}>
            <Root />
        </Provider>
   
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
