// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// application
import NavLinks from "./NavLinks";
import NavPanel from "./NavPanel";

import TopbarT from "./TopbarT";
import { Likemobile, LogoSvg } from "../../svg";
import Departments from "./Departments";

function Header(props) {
    const { layout } = props;
    let bannerSection;

    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle container_fm d-lg-flex d-none">
                <div className="site-header__logo">
                    <Link to="/">
                        <LogoSvg />
                    </Link>

                    {/* <Link to="/" className="ml-2">
                        <Likemobile />
                    </Link> */}
                </div>
                <div className="site-header__search"> </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

export default Header;
