// react
import React from "react";

// third-party
import { FormattedMessage } from "react-intl";
import DropdownLanguage from "./DropdownLanguage";
import NavPanelTopBar from "./NavPanelTopBar";

function TopbarT() {
    return (
        <div className="site-header__topbar topbarT d-lg-flex d-none">
            <div className="topbar__container container_fm">
                <DropdownLanguage />
                <NavPanelTopBar />
            </div>
        </div>
    );
}

export default TopbarT;
