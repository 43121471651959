import { SET_INITIAL_MAX_PRICE, SET_INITIAL_MIN_PRICE } from './generalActionTypes';

export const setInitialMinPrice = (payload) => {
    return ({
        type: SET_INITIAL_MIN_PRICE,
        payload,
    });
}

export const setInitialMaxPrice = (payload) => {
    return ({
        type: SET_INITIAL_MAX_PRICE,
        payload,
    });
}
