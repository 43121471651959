// react
import React, { Component } from "react";

// application
import departmentsArea from "../../services/departmentsArea";
import { LogoSvg, Menu18x14Svg, Close, Likemobile, MenuSvgMob } from "../../svg";
import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { mobileMenuOpen } from "../../store/mobile-menu";
import DepartmentsLinks from "./DepartmentsLinks";

class Departments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dep: [],
            open: false,
            fixed: false,
            area: null,
            openCatMenu: false,
            openSubCat: false,
        };
    }
    //dep: res.categories[0].children,
    componentDidMount() {
        fetch(`${url}/api/categories`)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    dep: res.categories,
                });
            });
        document.addEventListener("mousedown", this.handleOutsideClick);

        this.unsubscribeAria = departmentsArea.subscribe((area) => {
            this.setState({
                fixed: !!area,
                area,
            });
        });

        this.setState({
            fixed: !!departmentsArea.area,
            area: departmentsArea.area,
        });
    }

    handleChildClick = (e) => {
        this.setState((state) => ({
            openCatMenu: !state.openCatMenu,
        }));
    };
    handleSubCatClick = (e) => {
        let item = document.getElementById(`subCat${e}`);
        item.classList.add("db_block");
    };
    leaveSubCatClick = (e) => {
        let item = document.getElementById(`subCat${e}`);
        item.classList.remove("db_block");
    };
    handleButtonClick = () => {
        this.setState((state) => ({
            open: !state.open,
        }));
        this.setState({ active: !this.state.active });
    };

    render() {
        return (
            <div className="departments" ref={this.setWrapperRef}>
                {this.state.dep.length !== 0 ? (
                    <div className="departments-all--hovver">
                        <div className="departments-all-titles d-flex">
                            <span>
                                <FormattedMessage id="Menu.category" defaultMessage="All Categoryes" />
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="5.718" height="10" viewBox="0 0 5.718 10">
                                <path
                                    id="Icon_ionic-ios-arrow-forward"
                                    data-name="Icon ionic-ios-arrow-forward"
                                    d="M15.241,11.194,11.456,7.413a.712.712,0,0,1,0-1.009.721.721,0,0,1,1.012,0l4.287,4.284a.713.713,0,0,1,.021.985l-4.305,4.314a.715.715,0,0,1-1.012-1.009Z"
                                    transform="translate(-11.246 -6.196)"
                                    fill="#6a6960"
                                />
                            </svg>
                        </div>
                        <DepartmentsLinks dep={this.state.dep} func={this.handleButtonClick} />
                    </div>
                ) : null}
                <div className="cat_list_item_fms scroll-fms scrol-part">
                    {this.state.dep
                        ? this.state.dep.slice(0, 6).map((e) => {
                              return (
                                  <div
                                      onMouseEnter={() => (e.children.length ? this.handleSubCatClick(e.id) : "")}
                                      onMouseLeave={() => (e.children.length ? this.leaveSubCatClick(e.id) : "")}
                                  >
                                      <div className="shop-castalog-titlerow">
                                          <Link to={`/shop/catalog/${e.slug}`} className="shop-castalog_fms">
                                              <span>
                                                  <FormattedMessage id={e.slug} defaultMessage={e.name} />
                                              </span>
                                          </Link>
                                          {e.children.length ? (
                                              <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="5.718"
                                                  height="10"
                                                  viewBox="0 0 5.718 10"
                                              >
                                                  <path
                                                      id="Icon_ionic-ios-arrow-forward"
                                                      data-name="Icon ionic-ios-arrow-forward"
                                                      d="M15.241,11.194,11.456,7.413a.712.712,0,0,1,0-1.009.721.721,0,0,1,1.012,0l4.287,4.284a.713.713,0,0,1,.021.985l-4.305,4.314a.715.715,0,0,1-1.012-1.009Z"
                                                      transform="translate(-11.246 -6.196)"
                                                      fill="#6a6960"
                                                  />
                                              </svg>
                                          ) : (
                                              ""
                                          )}
                                      </div>
                                      {e.children.length ? (
                                          <div className="subcat_list_fms pad-top-cat_fm br_fm" id={`subCat${e.id}`}>
                                              <div className="subcat_list_item_fms">
                                                  {e.children.map((el) => (
                                                      <div
                                                          className="d-flex childe-sub-fms"
                                                          onMouseEnter={() =>
                                                              el.children.length ? this.handleSubCatClick(el.id) : ""
                                                          }
                                                          onMouseLeave={() => {
                                                              if (el.children.length) this.leaveSubCatClick(el.id);
                                                          }}
                                                      >
                                                          <Link
                                                              to={`/shop/catalog/${el.slug}`}
                                                              onClick={this.handleChildClick}
                                                              className="shop-castalog_fms "
                                                          >
                                                              <div className="sub_fms">
                                                                  <span>
                                                                      <FormattedMessage
                                                                          id={el.slug}
                                                                          defaultMessage={el.name}
                                                                      />
                                                                  </span>
                                                              </div>
                                                          </Link>
                                                          {el.children.length ? (
                                                              <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="5.718"
                                                                  height="10"
                                                                  viewBox="0 0 5.718 10"
                                                              >
                                                                  <path
                                                                      id="Icon_ionic-ios-arrow-forward"
                                                                      data-name="Icon ionic-ios-arrow-forward"
                                                                      d="M15.241,11.194,11.456,7.413a.712.712,0,0,1,0-1.009.721.721,0,0,1,1.012,0l4.287,4.284a.713.713,0,0,1,.021.985l-4.305,4.314a.715.715,0,0,1-1.012-1.009Z"
                                                                      transform="translate(-11.246 -6.196)"
                                                                      fill="#6a6960"
                                                                  />
                                                              </svg>
                                                          ) : (
                                                              ""
                                                          )}
                                                          {el.children.length ? (
                                                              <div
                                                                  className="subcat-sub_list_fms pad-top-cat_fm br_fm "
                                                                  id={`subCat${el.id}`}
                                                              >
                                                                  <div className="subcat_list_item_fms subs_fms">
                                                                      {el.children.map((elem) => (
                                                                          <div className="d-flex">
                                                                              <Link
                                                                                  to={`/shop/catalog/${elem.slug}`}
                                                                                  onClick={this.handleChildClick}
                                                                                  className="shop-castalog_fms"
                                                                              >
                                                                                  <div className="sub_fms thride-child-ms">
                                                                                      <span>
                                                                                          <FormattedMessage
                                                                                              id={elem.slug}
                                                                                              defaultMessage={elem.name}
                                                                                          />
                                                                                      </span>
                                                                                  </div>
                                                                              </Link>
                                                                          </div>
                                                                      ))}
                                                                  </div>
                                                              </div>
                                                          ) : (
                                                              ""
                                                          )}
                                                      </div>
                                                  ))}
                                              </div>
                                          </div>
                                      ) : (
                                          ""
                                      )}
                                  </div>
                              );
                          })
                        : ""}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Departments);
