// react
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { url } from "../../helper";
import { ArrowDown, LogoFooterSvg, LogoSvg } from "../../svg";
import SocialLinks from "../shared/SocialLinks";
import { Link } from "react-router-dom";

export default function FooterNewsletter(props) {
    return (
        <div className="del_fms">
            <div className="footer_contact_text_fms" onClick={() => (props.size < 768 ? props.openFunc("shipp") : "")}>
                <Link to="/">
                    <img src="/images/logos/hhphone.png" alt="" />
                </Link>
            </div>

            <ul className="footer-contacts__contacts">
                <li>
                    <SocialLinks className="footer-newsletter__social-links" shape="circle" />
                </li>
            </ul>
        </div>
    );
}
