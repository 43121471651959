import { SET_INITIAL_MAX_PRICE, SET_INITIAL_MIN_PRICE } from './generalActionTypes';

const initialState = {
    initialMaxPrice: null,
    initialMinPrice: null,
};

export default function generalReducer(state = initialState, action) {
    switch (action.type) {
        case SET_INITIAL_MAX_PRICE:
            return {
                ...state,
                initialMaxPrice: action.payload
            };
        case SET_INITIAL_MIN_PRICE:
            return {
                ...state,
                initialMinPrice: action.payload
            };
        default:
            return state
    }
}
