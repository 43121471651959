import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import React from "react";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_UPDATE_QUANTITIES,
    CART_TOKEN,
    CART_ADD_ALL,
    CART_DELETE_ALL,
} from "./cartActionTypes";
import { url } from "../../helper";
import ReactPixel from "react-facebook-pixel";
import { CheckToastSvg } from "../../svg";

export function cartAddItemSuccess(product, options = [], quantity = 1, itemId) {
    return {
        type: CART_ADD_ITEM,
        product,
        options,
        quantity,
        itemId,
    };
}

export function cartRemoveAllItemsSuccess() {
    return {
        type: CART_DELETE_ALL,
    };
}

export function cartRemoveAllItems() {
    return (dispatch) => {
        dispatch(cartRemoveAllItemsSuccess());
    };
}
export function cartAddItemSuccessAll(product, total, subtotal, quantity) {
    return {
        type: CART_ADD_ALL,
        items: product,
        total: total,
        quantity: quantity,
        subtotal: subtotal,
    };
}

export function addCartToken(cartToken) {
    return {
        type: CART_TOKEN,
        cartToken,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, options = [], quantity = 1, token, userToken) {
    const option = {
        method: "POST",
    };

    return (dispatch, selector) =>
        fetch(
            `${url}/api/checkout/cart/add/${product.id}?&locale=${selector().locale}&product_id=${
                product.id
            }&quantity=${quantity}&api_token=${token}${userToken ? `&token=${userToken}` : ""}`,
            option
        )
            .then((responce) => responce.json())
            .then((res) => {
                ReactPixel.trackCustom("Add To Cart");
                let message = <FormattedMessage id="successfully.added" defaultMessage={res.message} />;
                toast.success(
                    <span className="d-flex chek-fms">
                        <CheckToastSvg />
                        {message}
                    </span>
                );

                let itemId = res.data.items.filter((e) => e.product.id == product.id);
                dispatch(cartAddItemSuccess(product, [], quantity, itemId ? itemId[0].id : null));
            })
            .catch((err) => console.error(err));
}

export function cartRemoveItem(itemId, id, token, userToken) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        fetch(
            `${url}/api/checkout/cart/remove-item/${itemId}?api_token=${token}${userToken ? `&token=${userToken}` : ""}`
        )
            .then((responce) => responce.json())
            .then((res) => {
                dispatch(cartRemoveItemSuccess(id));
            })
            .catch((err) => console.error(err));
}

export function cartUpdateQuantities(quantities, cartItems, customerToken, ApiToken) {
    let qty = {};
    let options;
    quantities.map((upitems) => {
        qty[upitems.cartItem] = upitems.value;
    });

    const body = {
        token: customerToken,
        api_token: ApiToken,
        qty: qty,
    };

    if (customerToken.token) {
        options = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: customerToken.token,
                api_token: ApiToken,
                qty: qty,
            }),
        };
    } else {
        options = {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                api_token: ApiToken,
                qty: qty,
            }),
        };
    }

    return (dispatch) =>
        fetch(url + "/api/checkout/cart/update", options)
            .then((res) => res.json())
            .then((responce) => {
                responce.data ? dispatch(cartUpdateQuantitiesSuccess(quantities)) : console.error(responce.error);
            })
            .catch((error) => console.error(error));
}

// export function cartUpdateQuantities(quantities) {
//     // sending request to server, timeout is used as a stub
//     return (dispatch) => (
//         new Promise((resolve) => {
//             setTimeout(() => {
//                 dispatch(cartUpdateQuantitiesSuccess(quantities));
//                 resolve();
//             }, 500);
//         })
//     );
// }
