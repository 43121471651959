import React from 'react';
import { FormattedMessage } from 'react-intl';


function BlockHeaderCustom(props){
    return (
         <div>
             <p className="block-header__title__custom_fm">{props.title}</p>
        </div>
    )
}


export default BlockHeaderCustom