import { toast } from "react-toastify";
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, WISH_LIST } from "./wishlistActionTypes";

import React from "react";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";
import { CheckToastSvg, FailSvg } from "../../svg";

export function wishlistAddItemSuccess(product, wishItemId, check) {
    return {
        type: WISHLIST_ADD_ITEM,
        product,
        wishItemId,
    };
}
let wishMeassage;
export const setData = (data) => ({
    type: WISH_LIST,
    payload: data,
});

export function wishlistRemoveItemSuccess(productId) {
    return {
        type: WISHLIST_REMOVE_ITEM,
        productId,
    };
}

export function wishlistAddItem(product, token, check) {
    if (!token)
        return (dispatch) =>
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve(false);
                }, 500);
            });
    else
        return (dispatch, selector) =>
            fetch(`${url}/api/wishlist/add/${product.id}?token=${token}&locale=${selector().locale}`)
                .then((responce) => responce.json())
                .then((res) => {
                    if (res.message == "Item Successfully Added To Wishlist") {
                        wishMeassage = (
                            <span className="d-flex chek-fms">
                                <CheckToastSvg />
                                <FormattedMessage id="product.wish" defaultMessage={res.message} />
                            </span>
                        );
                    } else {
                        wishMeassage = (
                            <span className="d-flex faild-toast-fms">
                                <CheckToastSvg />
                                <FormattedMessage id="product.wish.remove" defaultMessage={res.message} />
                            </span>
                        );
                    }
                    toast.success(wishMeassage);

                    if (!check) dispatch(wishlistAddItemSuccess(product, res.data.id, true));
                    else dispatch(wishlistRemoveItemSuccess(product.id));
                })
                .catch((err) => console.error(err));
}

export function wishlistRemoveItem(productId, token) {
    // sending request to server, timeout is used as a stub

    return (dispatch) =>
        fetch(`${url}/api/wishlist/add/${productId}?token=${token}`)
            .then((responce) => responce.json())
            .then((res) => {
                dispatch(wishlistRemoveItemSuccess(productId));
            })
            .catch((err) => console.error(err));
}
