// react
import React, { useState } from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import Indicator from './Indicator';
import { Cart20Svg, Cross10Svg } from '../../svg';
import { cartRemoveItem } from '../../store/cart';
import { url } from '../../services/utils';
import {useSelector} from 'react-redux';
import { FormattedMessage } from 'react-intl';

function IndicatorCart(props) {

    const cartToken=useSelector(state=>state.cartToken)
    const customer=useSelector(state=>state.customer)
    const { cart, cartRemoveItem } = props;
    const[open,setOpen]=useState(false);

    let dropdown;
    let totals;

  
    const items = cart.items.map((item) => {
        // let options;
         let image;
         let  cartItemId;


         for(var i = 0; i < cart.items.length; i++) {
            if (cart.items[i].productID===item.product.id) {
                cartItemId = cart.items[i].cartItemId;
                break;
            }
        }

              
        if (item.product.images && item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link to={url.product(item.product)} className="product-image__body">
                        <img className="product-image__img" src={item.product.images[0].medium_image_url} alt="" />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id,cartItemId,cartToken,customer)}
                render={({ run, loading }) => {
                    const classes = classNames('dropcart__product-remove btn btn-light btn-sm btn-svg-icon', {
                        'btn-loading': loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        <Link to={url.product(item.product)}>{item.product.name}</Link>
                    </div>
                    {/* {options} */}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {' × '}
                        <span className="dropcart__product-price"><Currency value={parseInt(item.price)} /></span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">
                    {items}
                </div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {totals}
                            <tr>
                                <th><FormattedMessage id="total" defaultMessage="Ընդհանուր" />  </th>
                                <td><Currency value={parseInt(cart.total)} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link className="btn btn-secondary" to="/shop/cart" onClick={()=>setOpen(!open)}><FormattedMessage id="viewCart" defaultMessage="Զամբյուղը" /> </Link>
                    <Link className="btn btn-primary" to="/shop/checkout" onClick={()=>setOpen(!open)}><FormattedMessage id="checkout" defaultMessage="Վճարում" /> </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">
                <FormattedMessage id="cartEmpty" defaultMessage="Ձեր զամբյուղը դատարկ է!" />  
                </div>
            </div>
        );
    }


    const func=(bool)=>{

        setOpen(bool)
      }

    return (
    
        <Indicator url="/shop/cart" title="Զամբյուղ" func={func}  openEd={open} open={open} dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />
    );
}

const mapStateToProps = (state) => ({
    cart: state.cart,
  
});

const mapDispatchToProps = {
    cartRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
