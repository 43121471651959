// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { cartAddItem } from "../../store/cart";
import { url } from "../../services/utils";
import { FormattedMessage } from "react-intl";

function Suggestions(props) {
    const { context, className, products, cartAddItem } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const list =
        products &&
        products.reverse().map((product) => (
            <li key={product.id} className="suggestions__item">
                <div className="suggestions__item-info">
                    {product.id != -1 ? (
                        <Link
                            className="suggestions__item-name"
                            to={url.product(product)}
                            onClick={() => props.setQuery("")}
                        >
                            {product.name}
                        </Link>
                    ) : (
                        <div className="suggestions__item-name text-center">
                            <FormattedMessage id="SorryNothingFoundFor" defaultMessage="Sorry, nothing found for" /> "
                            {product.name}"
                        </div>
                    )}
                </div>
            </li>
        ));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">{list}</ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
