// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import { cartAddItem } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { wishlistRemoveItem } from "../../store/wishlist";
import { FormattedMessage } from "react-intl";
import { RemoveWish } from "../../svg";

// data stubs
import theme from "../../data/theme";
import ProductCard from "../shared/ProductCard";

function ShopPageWishlist(props) {
    const { wishlist, cartAddItem, wishlistRemoveItem, cartToken, customer } = props;
    const selectedData = useSelector((state) => state.locale);
    const breadcrumb = [
        { title: <FormattedMessage id="home" defaultMessage="Գլխավոր էջ" />, url: "" },
        { title: <FormattedMessage id="wishlist" defaultMessage="Ցանկությունների ցուցակ" />, url: "" },
    ];

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item, index) => {
            return (
                <div>
                    <ProductCard product={item} customer={customer} />
                    <div className="remove-wish_fm">
                        <AsyncAction
                            action={() => wishlistRemoveItem(item.id, customer.token)}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    onClick={run}
                                    className={classNames("product-card__wishlist", {
                                        "btn-loading": loading,
                                    })}
                                >
                                    <span>
                                        <FormattedMessage id="remove.wishlist.item" defaultMessage="Հեռացնել" />
                                    </span>
                                    <RemoveWish />
                                </button>
                            )}
                        />
                    </div>
                </div>
            );
        });

        content = (
            <div className="container_fm">
                <div className="wishlist">{itemsList}</div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">
                            {" "}
                            <FormattedMessage
                                id="wishlistEmpty"
                                defaultMessage="Ձեր ցանկությունների ցուցակը դատարկ է:"
                            />{" "}
                        </div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                <FormattedMessage id="continue" defaultMessage="Շարունակել" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wish List — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="" breadcrumb={breadcrumb} />
            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    cartToken: state.cartToken,
    customer: state.customer,
});

const mapDispatchToProps = {
    cartAddItem,
    wishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageWishlist);
