// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { url } from "../../helper";
// application
import BlockHeaderCustom from "../shared/BlockHeaderCustom";
import ProductCard from "../shared/ProductCard";
import StroykaSlick from "../shared/StroykaSlick";
import { FormattedMessage } from "react-intl";

const slickSettings = {
    "grid-4": {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 610,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    "grid-4-sm": {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 474,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    "grid-5": {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
    horizontal: {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    },
};

export default class BlockBankCarusel extends Component {
    constructor(props) {
        super(props);
        this.state = { banks: [] };
    }
    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };
    componentDidMount() {
        fetch(`${url}/api/brands`)
            .then((response) => response.json())
            .then((res) => {
                this.setState({ banks: res });
            });
    }

    banksColumns() {
        const columns = [];
        const { rows } = this.props;
        let banks = this.state.banks;

        if (banks !== undefined ? banks : []) {
            if (rows > 0) {
                banks = banks.slice();

                while (banks.length > 0) {
                    columns.push(banks.splice(0, rows));
                }
            }

            return columns;
        }
    }
    createMarkup = (item) => {
        return { __html: item };
    };
    render() {
        const { layout, title, withSidebar, onGroupClick, groups, loading } = this.props;
        const columns = this.banksColumns().map((column, index) => {
            const banks = column.map((bank) => (
                <div className="block-products-carousel__cell banks-fms">
                    <div className="block-banks-carousel__cell">
                        <div className="block-banks-titles partner-img-fms">
                            <img src={url + "/storage/" + bank.path} alt="" className="slider-img-fms" />
                        </div>
                    </div>
                </div>
            ));

            return (
                <div key={index} className="block-products-carousel__column">
                    {banks}
                </div>
            );
        });

        const blockClasses = classNames("block block-products-carousel", {
            "block-products-carousel--loading": loading,
            "block-products-carousel--has-items": columns.length > 0,
        });
        const containerClasses = classNames({
            container: !withSidebar,
        });

        return (
            <div className={blockClasses} data-layout={layout}>
                <div className="container_fm">
                    <p className="block-header__title__custom_fm">
                        <FormattedMessage id="products" defaultMessage="Ապրանքանիշներ" />
                    </p>
                    <BlockHeaderCustom
                        title={title}
                        groups={groups}
                        arrows
                        onNext={this.handleNextClick}
                        onPrev={this.handlePrevClick}
                        onGroupClick={onGroupClick}
                    />

                    <div className="block-bank-carousel__slider">
                        <div className="block-products-carousel__preloader" />

                        <StroykaSlick ref={this.setSlickRef} {...slickSettings[layout]}>
                            {columns}
                        </StroykaSlick>
                    </div>
                </div>
            </div>
        );
    }
}

BlockBankCarusel.propTypes = {
    title: PropTypes.string.isRequired,
    layout: PropTypes.oneOf(["grid-4", "grid-4-sm", "grid-5", "horizontal"]),
    rows: PropTypes.number,
    banks: PropTypes.array,
    groups: PropTypes.array,
    withSidebar: PropTypes.bool,
    loading: PropTypes.bool,
    onGroupClick: PropTypes.func,
};

BlockBankCarusel.defaultProps = {
    layout: "grid-4",
    rows: 1,
    banks: [],
    groups: [],
    withSidebar: false,
    loading: false,
    onGroupClick: undefined,
};
