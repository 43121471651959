// react
import React, { Component } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import YouTube from "react-youtube";
import { Next, Prev } from "../../svg";
const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dep: [],
            slickRef: "",
        };
    }
    departmentsAreaRef = null;
    media = window.matchMedia("(min-width: 992px)");

    componentDidMount() {
        fetch(`${url}/api/sliders`)
            .then((response) => response.json())
            .then((res) => {
                this.setState({
                    dep: res,
                });
            });

        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        // event.target.playVideo()
    }

    handleNextClick = () => {
        if (this.state.slickRef) {
            this.state.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.state.slickRef) {
            this.state.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.setState({ slickRef: ref });
    };
    render() {
        const { withDepartments } = this.props;
        const { dep } = this.state;

        let brandsList = dep
            ? dep.map((brand, index) => (
                <div key={index} className="block-brands__item banner-fms">
                    {!brand.slider_path ? (
                        <Link to="/">
                            <img src={url + "/storage/" + brand.path} alt="" />
                        </Link>
                    ) : (
                        <YouTube
                            videoId="tWgpd761yRs"
                            opts={{ height: "400", width: "100%", playerVars: { autoplay: dep.lrngth == 1 ? 1 : 0 } }}
                            onReady={this.onReady}
                        />
                    )}
                </div>
            ))
            : "";

        const blockClasses = classNames("block-slideshow block", {
            "block-slideshow--layout--full": !withDepartments,
            "block-slideshow--layout--with-departments": withDepartments,
        });

        const slides = this.state.dep.map((slide, index) => {
            // const image = slide.path//(withDepartments ? slide.image_classic : slide.image_full);
            const image = `images/slides/slideHome.png`;

            return (
                <div>
                    {/* <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(images/slides/slideHome.png)`,
                        }}
                    /> */}
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${url}/storage/${image})`,
                        }}
                    />

                    {/* <div className="container_fm ">
                        <div className="block-slideshow__slide-content"> */}
                    {/* <div
                                className="block-slideshow__slide-title"
                            //    dangerouslySetInnerHTML={{ __html: slide.content }}
                            >
                                <FormattedMessage id="header.categories1" defaultMessage="Գնե՜ք ամանորյա նվերներ Ձեր սիրելիներին" />
                                </div>
                            <div className="block-slideshow__slide-text"
                                //dangerouslySetInnerHTML={{ __html: slide.text }}
                            >
                    <FormattedMessage id="header.categories1" defaultMessage="Մեզ մոտ գործում են 
նախատոնական զեղչեր " />

                                
                                </div> */}

                    {/* <div className="block-slideshow__slide-button">
                                <Link to="/shop/catalog" className="btn btn-primary btn-lg">Գնել հիմա</Link>
                            </div> */}
                    {/* </div>
                    </div> */}
                </div>
            );
        });

        return (
            <div className={blockClasses}>
                <div>
                    {withDepartments && (
                        <div className="absolute col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                    )}
                    <div>
                        <div className="block-slideshow__body">
                            <div className="partner_fms slide_fms">
                                <div className="slider_button_fms banner-slider-fms">
                                    <span onClick={() => this.handlePrevClick()}>
                                        <Prev />
                                    </span>
                                    <span onClick={() => this.handleNextClick()}>
                                        <Next />
                                    </span>
                                </div>
                                <StroykaSlick ref={this.setSlickRef} {...slickSettings}>
                                    {brandsList}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
