import React, { useState, useEffect } from "react";
import { url } from "../../helper";
import { BankssSvg, CalcSvg } from "../../svg";
import { FormattedMessage } from "react-intl";
import { cssNumber } from "jquery";

function CalculateResult({ monthlyPrice }) {
    function prettify(num) {
        let n = num.toString();
        return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ",");
    }
    return (
        <div className="mt-3">
            <b>
                <span className="amd-monthly">
                    <FormattedMessage id="monthly" defaultMessage="Monthly" />:
                </span>
                {`${prettify(monthlyPrice)}`}
                <span className="amd-fms">
                    <FormattedMessage id="curency" defaultMessage="դրամ" />
                </span>
            </b>
        </div>
    );
}

function ProductCalculator({ price: productPrice, cost }) {
    const [banks, setBanks] = useState({
        list: [],
        current: null,
    });
    const [month, setMonth] = useState(null);
    const [cahce, setCahce] = useState(0);
    const [result, setResult] = useState(null);
    // const [message, setMessage] = useState(null);
    const [sheight, setHeight] = useState("100%");

    useEffect(() => {
        fetch(`${url}/api/banks?locale=en`)
            .then((res) => res.json())
            .then(({ data }) => {
                setBanks({
                    ...banks,
                    list: data,
                });
            });
    }, []);

    function generateMonthlyPrice() {
        if (banks?.current?.rate) {
            const { rate, service_fee, credit_amount_rate } = banks.current;
            if (rate && service_fee != undefined && credit_amount_rate && month) {
                const price = +productPrice.split(".")[0];
                let creditAmountRatePrice = price + (price * credit_amount_rate) / 100;
                if (cost) {
                    creditAmountRatePrice = cost;
                }

                let principal = parseFloat(creditAmountRatePrice);
                let interest = parseFloat(rate) / 100 / 12;
                let payments = month;
                let x = Math.pow(1 + interest, payments);
                let service = (service_fee * cost) / 100;
                let monthly = Math.round(((principal - cahce) * x * interest) / (x - 1) + service);

                console.log(service, "padding-right: 10px;");
                setResult({ monthlyPrice: monthly });
            }
        }
    }

    function handleChangeCache(e) {
        const priceFromTag = +e.currentTarget.value;
        const { credit_amount_rate } = banks.current;
        const price = +productPrice.split(".")[0];
        const creditAmountRatePrice = parseFloat(price + (price * credit_amount_rate) / 100);

        if (priceFromTag >= 0 && priceFromTag <= creditAmountRatePrice) {
            setCahce(priceFromTag);
            // if (message) {
            //     setMessage(null)
            // }
        }
    }

    return (
        banks.list.length > 0 && (
            <div className="product--layout-second-section" style={{ height: sheight }}>
                <div className="calculator-container">
                    <div>
                        <div>
                            <CalcSvg />
                        </div>
                        <div>
                            <h3>
                                <FormattedMessage id="credit" defaultMessage="Credit" />
                            </h3>
                            <h3>
                                <FormattedMessage id="calculator" defaultMessage="Calculator" />
                            </h3>
                        </div>
                    </div>
                    <div className="input-maney-fms">
                        <div className="form-group col-md-6">
                            <p>
                                <FormattedMessage id="banks" defaultMessage="Banks" />
                            </p>
                            <FormattedMessage id="choose.bank" defaultMessage="Choose bank">
                                {(placeholder) => (
                                    <select
                                        className="banks-select"
                                        onChange={(elem) => {
                                            const current = banks.list.find((e) => e.id == elem.currentTarget.value);
                                            setBanks({
                                                ...banks,
                                                current,
                                            });
                                            setHeight("unset");
                                            setMonth(current.available_periods[0].period);
                                        }}
                                    >
                                        <option value="">{placeholder}</option>
                                        {banks.list.map(({ name, id }) => {
                                            return (
                                                <option key={id} value={id}>
                                                    {name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            </FormattedMessage>
                        </div>
                        {banks?.current ? (
                            <div className="input-maney-fms">
                                <div className="form-group col-md-6">
                                    <p>
                                        <FormattedMessage id="cache" defaultMessage="Cache" />
                                    </p>
                                    <input
                                        type="number"
                                        value={cahce}
                                        name="last_name"
                                        className="form-control"
                                        onChange={handleChangeCache}
                                    />
                                    {/* {message ? <p>{message}</p> : null} */}
                                </div>
                            </div>
                        ) : null}
                        {banks?.current?.available_periods ? (
                            <div className="form-group col-md-6">
                                <p>
                                    <FormattedMessage id="period" defaultMessage="Period" />
                                </p>
                                <select
                                    className="banks-select"
                                    onChange={(e) => {
                                        setMonth(+e.currentTarget.value);
                                    }}
                                >
                                    {banks.current.available_periods.map(({ id, period }) => {
                                        return (
                                            <FormattedMessage id="month" defaultMessage="Ամիս">
                                                {(placeholder) => {
                                                    return (
                                                        <option key={id} value={period}>
                                                            {period}
                                                            {"  "}
                                                            {placeholder}
                                                        </option>
                                                    );
                                                }}
                                            </FormattedMessage>
                                        );
                                    })}
                                </select>
                            </div>
                        ) : null}

                        <div className="calculator-button-fms">
                            <button className="add_cart_fms" onClick={generateMonthlyPrice}>
                                <FormattedMessage id="calculate" defaultMessage="Calculate" />
                            </button>
                        </div>
                    </div>
                    {result ? (
                        <span className="d-flex justify-content-center">
                            <CalculateResult {...result} />
                        </span>
                    ) : null}

                    <div className="banks-svg">
                        <BankssSvg />
                    </div>
                </div>
            </div>
        )
    );
}

export default ProductCalculator;
