// react
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { url } from "../../helper";
import { FormattedMessage } from "react-intl";

// application

export default function BlockCategoryBaners(props) {
    const [dep, setDep] = useState([]);
    const { layout } = props;
    const selectedData = useSelector((state) => state.locale);
    useEffect(() => {
        fetch(`${url}/api/six-boxes`)
            .then((response) => response.json())
            .then((res) => {
                setDep(res);
            });
    }, []);

    const createMarkup = (item) => {
        return { __html: item };
    };
    let catList = dep.map((list, index) => {
        const title = Object.keys(list).includes(selectedData) ? list[selectedData] : list["hy"];
        return (
            <div className="category-hp-body">
                <div key={index} className="category-container">
                    <Link to={list.url} className="shop-castalog_fms shop-cat_fms">
                        <img src={url + "/storage/" + list.path} alt="" className="slider-img-fms" />
                        <span className="slider-span-fms">
                            <div className="category-hp-titles " dangerouslySetInnerHTML={createMarkup(title)} />
                        </span>
                    </Link>
                    <div className="category-hp-titles"></div>
                </div>
            </div>
        );
    });

    const half = Math.ceil(catList.length / 2);
    const firstHalf = catList.slice(0, half);
    const secondHalf = catList.slice(-half);
    return (
        <>
            <div className={`block block-products block-products--layout--${layout}`}>
                <div className="container_fm">
                    <div className="category-containers-fms first-fms">{firstHalf}</div>
                    <div className="category-containers-fms second-fms">{secondHalf}</div>
                </div>
            </div>
        </>
    );
}

BlockCategoryBaners.propTypes = {
    title: PropTypes.object.isRequired,
    featuredProduct: PropTypes.object,
    products: PropTypes.array,
    layout: PropTypes.oneOf(["large-first", "large-last"]),
};

BlockCategoryBaners.defaultProps = {
    products: [],
    layout: "large-first",
};
