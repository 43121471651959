// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

// application
import CartIndicator from "./IndicatorCart";
import Departments from "./Departments";
import Indicator from "./Indicator";
import IndicatorAccount from "./IndicatorAccount";
import IndicatorSearch from "./IndicatorSearch";
import NavLinks from "./NavLinks";
import Search from "./Search";
import { Cart120Svg, Cart20Svg, Close, Heart20Svg, LogoSmallSvg, Nav, Person20Svg, Search20Svg } from "../../svg";
import { Modal } from "reactstrap";
import LoginBlock from "../blocks/LoginBlock";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

function NavPanelTopBar(props) {
    const { layout } = props;
    let location = useLocation();
    const customer = useSelector((state) => state.customer.token);
    const customerName = useSelector((state) => state.customer.customerName);
    const cart = useSelector((state) => state.cart);

    let logo = null;
    let departments = null;
    let searchIndicator;
    const searchInput = React.createRef();

    useEffect(() => {
        // SetCart(state)
    }, [location, cart]);

    const [login, SetLogin] = useState(false);
    const [forgotPass, SetForgotPass] = useState(false);
    const [reg, SetReg] = useState(false);
    const [modal, SetModal] = useState(false);

    const closeModal = () => {
        SetModal(false);
        SetReg(false);
        SetLogin(false);
        SetForgotPass(false);
    };

    const clicked = (type, check) => {
        SetModal(true);
        if (type == "login") SetLogin(check);

        if (type == "reg") SetReg(check);

        if (type == "forgot") SetForgotPass(check);
    };

    const close = (type, check) => {
        clicked(type, check);
    };

    if (layout === "compact") {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/">
                    <LogoSmallSvg />
                </Link>
            </div>
        );

        searchIndicator = <IndicatorSearch />;
    }

    if (layout === "default") {
        departments = (
            <div className="nav-panel__departments">
                <Departments />
            </div>
        );
    }

    return (
        <>
            <Modal className="nav_modal_fm" isOpen={modal} toggle={closeModal} centered size="xl">
                <div className="quickview">
                    <button
                        className="quickview__close"
                        type="button"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        <Close />
                    </button>
                    <LoginBlock
                        login={login}
                        reg={reg}
                        clicked={clicked}
                        forgot={forgotPass}
                        close={close}
                        closeModal={closeModal}
                    />
                </div>
            </Modal>
            <div className="">
                <div className="nav-panel__container ">
                    <div
                        className="nav-count_fm indicator-log-in"
                        onClick={() => (customer == "" ? clicked("login", true) : "")}
                    >
                        <Indicator
                            url={customer == "" ? "/" : "/account/dashboard"}
                            title={
                                customerName ? (
                                    customerName.first_name || (
                                        <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" />
                                    )
                                ) : (
                                    <FormattedMessage id="login.log.in" defaultMessage="Մուտք" />
                                )
                            }
                            icon={<Person20Svg />}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

NavPanelTopBar.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

NavPanelTopBar.defaultProps = {
    layout: "default",
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavPanelTopBar);
