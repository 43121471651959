// react
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function Currency(props) {
    const { value } = props;

    function prettify(num) {
        var n = num.toString();
        return n.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1" + ',');
    }
    return <React.Fragment>
        <span className="price_fms">
            {`${prettify(value)}`}
        </span>
        <span><FormattedMessage id="curency" defaultMessage="դրամ" /></span>
    </React.Fragment>;

}

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
