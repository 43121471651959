// react
import React, { useState } from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';
import { FormattedMessage } from 'react-intl';
import {url} from '../../helper'
// data stubs
import theme from '../../data/theme';
import { useHistory } from 'react-router-dom';
import AccountLogin from './AccountLogin';

export default function AccountPageLogin() {

    const [input,Setinput]=useState({});
    const history = useHistory();
 
    const breadcrumb = [
        { title: <FormattedMessage id="topbar.home" defaultMessage="Գլխավոր" />, url: '' },
        { title: <FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" />, url: '' },
    ];
 
    const checkEmail=(email)=>{

         if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
           return "true";
         }
         // SetError( [...error,[{'pass':'not equal'}]])
          return "false";     

    }


    const comfirmPss=()=>{
        return false;
    }

    const register=(event)=>{

        event.preventDefault();

        checkEmail();        
        comfirmPss();


        let options={

             method:"POST",
             headers: {
                'Content-Type': 'application/json'
            },
             body:JSON.stringify({
                 email:input.email,
                 password:input.password,
                 password_confirmation:input.confirm_password
             })
        };
 
            fetch(url+'/api/customer/register',options)        
            .then(responce=>responce.json())
            .then(res=>{
                if(res.message=="Your account has been created successfully."){
    
                     history.push('/');
                }else{
 
                    if (res.email.length) {
                        alert(res.email[0])
                    }

                    if (res.password.length) {
                        
                        alert(res.password[0])
                    }
                     
                }
    
               
            })
            .catch(err=>console.error(err,'asdasdasd'));
           

        

        

    }

    const handleChange=(e)=>{

        input[e.target.name]=e.target.value;
        Setinput(input);
        
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

<FormattedMessage id="topbar.myAccount" defaultMessage="Իմ հաշիվը" >
                              
                   
                   { account => 
                    <PageHeader header={account} breadcrumb={breadcrumb} />
                  }   
             </FormattedMessage>

            <div className="block">
                <div className="container">
                    <div className="row justify-content-center">
                         
                        <div className=" col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title">
                                        
                                        <FormattedMessage id="topbar.register" defaultMessage="Գրանցում" />
                                        
                                     </h3>
                                    <form >
                                        <div className="form-group">
                                             <FormattedMessage id="topbar.email" defaultMessage="էլ․ Հասցե" >                  
                   
                                                { placeholder => 
                                                <input id="register-email"
                                                
                                                name='email'
                                                onChange={handleChange}
                                                type="email"
                                                className="form-control form-control-sm"
                                                placeholder={placeholder}
                                                />}
                   

                                              </FormattedMessage>
                                        </div>
                                        <div className="form-group">
                                            <FormattedMessage id="topbar.password" defaultMessage="Գաղտնաբառ" > 
                                                        { placeholder =><input
                                                            id="register-password"
                                                            name="password" 
                                                            type="password"
                                                            onChange={handleChange}
                                                            className={"form-control form-control-sm " }
                                                            placeholder={placeholder}
                                                        />}
                                            </FormattedMessage>
                                             
                                        </div>
                                        <div className="form-group">
                                            <FormattedMessage id="passwordConfirm" defaultMessage="Կրկնել Գաղտնաբառը" > 
                                                    { placeholder =><input
                                                            id="register-repat-password"
                                                            name="confirm_password"
                                                            type="password"
                                                            onChange={handleChange}
                                                            className="form-control form-control-sm"
                                                            placeholder={placeholder}
                                                        />}
                                            </FormattedMessage>
                                        </div>
                                        <button type="submit" onClick={register} className="btn btn-primary mt-2 mt-md-3 mt-lg-4">
                                        <FormattedMessage id="topbar.register" defaultMessage="Գրանցում" />
                                        </button>
                                    </form>
                                </div>


                        
                            </div>
                        </div>
        
                       <div className="col-md-6 d-flex">  
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">  
                                 <AccountLogin />

                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}