import { AUTHENTICATED, CUSTOMER_TOKEN, CUSTOMER_ID,CUSTOMER_NAME } from './customerActionTypes'

const initialState = {
    authenticated: false,
    token: '',
    customerId: '',
    customerName: '',
};

const customerReducer = (state = initialState, action) => {

    switch (action.type) {


        case AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload
            }

        case CUSTOMER_TOKEN:
            return {
                ...state,
                token: action.payload
            }

            case CUSTOMER_NAME:
                return {
                    ...state,
                    customerName: action.payload
                }

        case CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload
            }

        default:
            return state;
    }
};

export default customerReducer;