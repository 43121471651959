import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import languages from "../../i18n";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { url } from "../../helper";
import DropdownLanguage from "./DropdownLanguage";
import { FormattedMessage } from "react-intl";

function NavLinks(props) {
    const selectedData = useSelector((state) => state.locale);
    const [navLinks, SetNavLinks] = useState();
    let linksList = "";

    useEffect(() => {
        fetch(`${url}/api/cms/menus?locale=${selectedData}`)
            .then((response) => response.json())
            // .then(res=>console.log(res,"Hola"))
            .then((res) => {
                SetNavLinks(res.data);
            });
    }, [selectedData]);

    const handleMouseEnter = (event) => {
        const { locale } = props;
        const { direction } = languages[locale];

        const item = event.currentTarget;
        const megamenu = item.querySelector(".nav-links__megamenu");
        const linksList = "";
        if (megamenu) {
            const container = megamenu.offsetParent;
            const containerWidth = container.getBoundingClientRect().width;
            const megamenuWidth = megamenu.getBoundingClientRect().width;
            const itemOffsetLeft = item.offsetLeft;

            if (direction === "rtl") {
                const itemPosition = containerWidth - (itemOffsetLeft + item.getBoundingClientRect().width);

                const megamenuPosition = Math.round(Math.min(itemPosition, containerWidth - megamenuWidth));

                megamenu.style.left = "";
                megamenu.style.right = `${megamenuPosition}px`;
            } else {
                const megamenuPosition = Math.round(Math.min(itemOffsetLeft, containerWidth - megamenuWidth));

                megamenu.style.right = "";
                megamenu.style.left = `${megamenuPosition}px`;
            }
        }
    };

    if (navLinks) {
        linksList = navLinks.map((item, index) => {
            return (
                <li key={index} className="nav-links__item">
                    <NavLink exact activeClassName="is-active" to={`/page/${item.page_id}`}>
                        <span>{item.name}</span>
                    </NavLink>
                </li>
            );
        });
    }

    return (
        <ul className="nav-links__list">
            {linksList}
            <li className="nav-links__item" onMouseEnter={handleMouseEnter}>
                <NavLink exact activeClassName="is-active" to="/blog">
                    <span>
                        <FormattedMessage id="blog" defaultMessage="Բլոգ" />
                    </span>
                </NavLink>
            </li>
        </ul>
    );
}

NavLinks.propTypes = {
    /** current locale */
    //  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
