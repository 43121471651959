// react
import React from "react";

// third-party
import { Link } from "react-router-dom";
import { url } from "../../helper";

export default function FirstBlockBanner(props) {
    // let strrr = props.content.replace(/<p>/gi, "");
    // strrr = strrr.replace(/<\/p>/gi, "--delete--");
    // console.log(strrr.split("--delete--"));

    const createMarkup = (item) => {
        return { __html: item };
    };
    return (
        <div className="block block-banner first-block-banner-fms">
            <div className="container_fm">
                <Link to="/" className="block-banner__body">
                    <div className="first-block-banner first-bann-fms">
                        <img src={url + "/storage/" + props.url} alt="" className="first-img-fms" />
                    </div>
                    <div className="first-block-banner bottom-part-ofBanner">
                        <img src={url + "/storage/" + props.secondUrl} alt="" />
                        <div className="first-block-banner-title">
                            <div
                                className="first-block-banner-titles"
                                dangerouslySetInnerHTML={createMarkup(props.title)}
                            />

                            <div
                                className="first-block-banner-content"
                                dangerouslySetInnerHTML={createMarkup(props.content)}
                            />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
}
