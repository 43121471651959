// react
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { url } from "../../helper";
// third-party

// application

function CreepingLine({ locale }) {
    const [creepingLine, setCreepingLine] = useState();
    useEffect(() => {
        fetch(`${url}/api/custom_settings?locale=${locale}`)
            .then((response) => response.json())
            .then((res) => {
                setCreepingLine(res);
            });
    }, [locale]);

    return (
        <marquee direction="left" className="ceeping-line-header">
            {creepingLine !== undefined ? creepingLine.creeping_line : "****************"}
        </marquee>
    );
}

const mapStateToProps = ({ locale }) => ({
    locale
})
export default connect(mapStateToProps)(CreepingLine);
